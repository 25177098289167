

/*
    Home page
*/

 .bluebox {
        max-width: 640px;
    }

// ==========================================================================
// Intro__fullbleed
// ==========================================================================
.intro__fullbleed,
.intro__fullbleed .intro__mobileimg {
  diplay: flex;
 
}

.intro__fullbleed {
    background-repeat: no-repeat;
    background-position: 0% 0%;
    background-size: cover;

     @include media-breakpoint-up(lg) {
        height: 47vw;
        background-position: 30%;
     }

    @include media-breakpoint-down(lg) {
        height: 100%;
        max-height: unset;
        min-height: unset;
    }

    @include media-breakpoint-down(md) {
        background-image: none!important;
    }


    .row {
        height: 100%;
    }
    .row.bottom-left {
          position: relative;
          bottom: 3rem;
    }

    .intro__callout-text {
        min-height: 60vh;
        background-color: $light-grey;
        width: 950px;
        padding: 7%;

        @include media-breakpoint-down(md) {
            margin-top: -65px;
            min-height: auto;
            height: 100%;
            text-align: center;
        }

        @include media-breakpoint-down(lg) {
            min-height: 50vh;
        }

    }
    .intro__callout-text.bottom-left {
      min-height: unset;
      max-height: unset;
      height: unset;
      padding: 4rem;
      align-items: flex-start;

      @include media-breakpoint-up(lg) {
        position: absolute !important;
        bottom: 15px;
    }//media-breakpoint-up(md)
    }

    .intro__mobileimg {
        height: 320px;
        min-height: 50vh!important;
        background-position: 16% 20% !important;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .intro__heading {
        margin-bottom: 24px;
    }

    .intro__link {
        margin-top: 48px;

         @include media-breakpoint-down(md) {
            margin-top: 24px;
        }
    }
     &.mobileOnDesktop {
        @include media-breakpoint-up(lg) {
            height: 100%;
            max-height: unset;
            .intro__mobileimg {
                height: 40vw;
            }

             .bluebox {
                    max-width: 1137px;
                }
            .intro__callout-text {
                text-align: center;
                max-height: 412px;
                height: 21%;
                min-height: unset;
                max-width: 1577px;
                margin: 0 auto;
                margin-top: -10%;
            }
        }
    }

}

// ==========================================================================
// .centered intro__fullbleed
// ==========================================================================
.centered.intro__fullbleed,
.centered.intro__mobileimg {
    background-position: top!important;
    @include media-breakpoint-down(md) {
            margin-bottom: 450px;
    }
    @include media-breakpoint-up(lg) {
        
        margin-bottom: 300px;
    }
}
.centered.intro__fullbleed{
   height: 40vw;
    @include media-breakpoint-down(md) {
    
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 300px;
    }

    .intro__callout-text {
      
     
      padding: 5% 7%;
      max-width: unset;
      min-width: 85%;
      max-height: unset;
      height: 60%;
      width: 2000px !important;
        @include media-breakpoint-down(md) {
          
        }
        @include media-breakpoint-up(lg) {
            position: relative;
            top: 65%;
           
        }
    }
  
}
.centered.intro__fullbleed.mobileOnDesktop .bluebox {
     @include media-breakpoint-up(lg) {
         max-width: unset !important;
    }
   
}















   
  .play-icon {
        margin-left: -10px;

        height: 24px;
        width: 24px;

        @include media-breakpoint-up(md) {
            height: 27px;
            width: 27px;
        }
    }

.link-columns {
    padding-bottom: 60px;
    max-width: 1920px;
    padding-left: 7%;
    padding-right: 7%;

    .link-columns__heading {
        margin-bottom: 5rem;

        @include media-breakpoint-down (md) {
            margin-bottom: 3rem;
            margin-left: 8.33333%;
        }
    }

    @include media-breakpoint-down (md) {
        padding-left: 0px;
        padding-right: 0px;
    }

    .row {
        max-width: 100vw;

        @include media-breakpoint-down (md) {
            max-width: none;
            min-width: 100vw;
        }

    }

    .link-column {
        margin-bottom: 60px;

        @include media-breakpoint-down (md) {
            padding-left: 0px;
            padding-right: 0px;
            margin-bottom: 26px;
        }

        .link_column__content {
            position: relative;

            @include media-breakpoint-down(md) {
                width: 100%;
                margin-bottom: 50px;
            }
        }//.link-column__content

        .image-container {

            .link-column__image {
                background-position: center 16%;
                background-size: cover;
                min-height: 300px;

                @include media-breakpoint-down(md) {
                    background-position: center 16%;
                }
            }
    
        }//.image-container

        .image__caption {
                background-color: $white;
                padding: 15px 0px 0px;
                position: absolute;
                bottom: 0;
                left: 0;
                max-width: 384px;
                width: 80%;
                background-size: cover;
                background-position: top center;
                max-height: 300px;

                @include media-breakpoint-down(md) {
                    position: relative;
                    top: -40px;
                    left: 0;
                    right: 0px;
                    padding: 20px 28px 20px 28px;
                    margin: 0 auto;
                    width: 83.33333%;
                    max-width: unset;
                }

                h2 {
                    font-family: Europa, sans-serif;
                    color: $twilight-blue;
                    font-size: 28px;
                    font-size: 2.8rem;
                    font-weight: normal;
                    letter-spacing: normal;
                    line-height: 1.25;
                    margin-bottom: -7px;

                    @include media-breakpoint-down(lg) {
                        font-size: 22px;
                        font-size: 2.2rem;
                        margin-bottom: -5px;
                    }//mq lg
                }//h2
            }//.image__caption       
   

        .link-column__text {
            margin-top: 44px;
            max-width: 480px;

            @include media-breakpoint-down(md) {
                padding: 0px 28px 20px 28px;
                margin: 0 auto;
                width: 83.33333%;
                max-width: unset;

            }

            .heading3 {
                margin-bottom: 5px;
            }

            p {

                font-size: 1.5rem;
                margin-bottom: 20px;
                margin-top: 20px;
            }
        }//.link-column__text
    }//.link-column
}//.link-columns

.link--scroll {
    animation-name: bounce;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    display: inline-flex;

    &:hover {
        cursor: pointer;
        text-decoration: none;
    }
}

@keyframes bounce {
    0%, 100% {
        transform: translate3d(0px, 0px, 0px);
    }
    50% {
        transform: translate3d(0px, -10px, 0px);
    }
}

.link__icon {
    max-height: 30px;
    &:hover {
        cursor: pointer;
    }
}

/* ------ HOME HERO BANNER ------- */
.home-hero-banner {
    background-color: #074a76;
    padding: 5% 8%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    p {
        color: white;
        margin: 0;
        font-style: italic;
    }

    .hero-banner-inner {
        display: flex;
        flex-direction: column;
        margin-right: 3rem;
        min-width: unset;
        align-items: flex-start;
        justify-content: center;

        h4 {
            color: white;
            cursor: auto;
            padding: 0;
            margin: 0;
            border: none;
        }
        h2 {
            font-size: 35px;
            color: white;
            margin: 0;
            margin-bottom: 1rem;
        }
    }
    .home-hero-buttons {
        width: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 1rem;

        a {
            color: white;
            border-color: white;
            padding: 1rem 2.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            text-align: center;
        }
    }
    @media (min-width: 1700px) {
        // display: grid;
        // grid-template-columns: 75% 25%;
        // grid-template-rows: auto auto;
        // justify-items: center;
        // align-items: flex-start;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;


        .hero-banner-inner {
            width: 60%;
            // grid-row: 1;
            // grid-column: 1;
        }
        p {
            order: 3
            // grid-row: 2;
            // grid-column-start: 1;    
            // grid-column-end: 3;
        }   
        .home-hero-buttons {
            // grid-row: 1;
            // grid-column: 2;
            // align-self: flex-end;
            order: 2;
            width: 35%;
            a {
                font-size: 16px;
            }
        }
    }
    @media (max-width: 1400px) {
        .hero-banner-inner {
            h2 {
                font-size: 32px;
            }
        }
    }
    @media (max-width: 1310px) {
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        .hero-banner-inner {
            margin: 0;
            h4, h2, p {
                width: 100%;
            }
            h2 {
                margin: 0.5rem 0;
            }
            h4 {
                line-height: 1.5 !important;
            }
        }
        .home-hero-buttons {
            margin-top: 2rem;
            order: 3;
            width: auto;
            justify-content: flex-start;
            a {
                font-size: 14px;
            }
        }
    }
    @media (max-width: 992px) {
        .home-hero-buttons {
            justify-content: center;
        }
    }
    // @media (max-width: 1330px) {     
    //     .hero-banner-inner {
    //         align-items: center;
    //         text-align: center;
    //     }
    // }
    // @media (max-width: 991px) {
    //     align-items: center;

    //     .hero-banner-inner {
    //         align-items: center;
    //     }
    // }
    // @media (max-width: 520px) {
    //     padding: 10%;

    //     .home-hero-buttons {
    //         flex-direction: column;
    //         width: 100% !important;
    //             a {
    //             width: 100% !important;
    //             }
    //     }  
    // }
}
